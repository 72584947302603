body.swal2-shown {
  overflow-y: hidden; }

body.swal2-iosfix {
  position: fixed;
  left: 0;
  right: 0; }

.swal2-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 10px;
  background-color: transparent;
  z-index: 1060; }

.swal2-container.swal2-fade {
  transition: background-color .1s; }

.swal2-container.swal2-shown {
  background-color: rgba(0, 0, 0, 0.4); }

.swal2-modal {
  background-color: #fff;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  border-radius: 5px;
  box-sizing: border-box;
  text-align: center;
  margin: auto;
  overflow-x: hidden;
  overflow-y: auto;
  display: none;
  position: relative;
  max-width: 100%; }

.swal2-modal:focus {
  outline: none; }

.swal2-modal.swal2-loading {
  overflow-y: hidden; }

.swal2-modal .swal2-title {
  color: #595959;
  font-size: 30px;
  text-align: center;
  font-weight: 600;
  text-transform: none;
  position: relative;
  margin: 0 0 .4em;
  padding: 0;
  display: block;
  word-wrap: break-word; }

.swal2-modal .swal2-buttonswrapper {
  margin-top: 15px; }

.swal2-modal .swal2-buttonswrapper:not(.swal2-loading) .swal2-styled[disabled] {
  opacity: .4;
  cursor: no-drop; }

.swal2-modal .swal2-buttonswrapper.swal2-loading .swal2-styled.swal2-confirm {
  box-sizing: border-box;
  border: 4px solid transparent;
  border-color: transparent;
  width: 40px;
  height: 40px;
  padding: 0;
  margin: 7.5px;
  vertical-align: top;
  background-color: transparent !important;
  color: transparent;
  cursor: default;
  border-radius: 100%;
  animation: rotate-loading 1.5s linear 0s infinite normal;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.swal2-modal .swal2-buttonswrapper.swal2-loading .swal2-styled.swal2-cancel {
  margin-left: 30px;
  margin-right: 30px; }

.swal2-modal .swal2-buttonswrapper.swal2-loading :not(.swal2-styled).swal2-confirm::after {
  display: inline-block;
  content: '';
  margin-left: 5px 0 15px;
  vertical-align: -1px;
  height: 15px;
  width: 15px;
  border: 3px solid #999999;
  box-shadow: 1px 1px 1px #fff;
  border-right-color: transparent;
  border-radius: 50%;
  animation: rotate-loading 1.5s linear 0s infinite normal; }

.swal2-modal .swal2-styled {
  border: 0;
  border-radius: 3px;
  box-shadow: none;
  color: #fff;
  cursor: pointer;
  font-size: 17px;
  font-weight: 500;
  margin: 15px 5px 0;
  padding: 10px 32px; }

.swal2-modal .swal2-image {
  margin: 20px auto;
  max-width: 100%; }

.swal2-modal .swal2-close {
  background: transparent;
  border: 0;
  margin: 0;
  padding: 0;
  width: 38px;
  height: 40px;
  font-size: 36px;
  line-height: 40px;
  font-family: serif;
  position: absolute;
  top: 5px;
  right: 8px;
  cursor: pointer;
  color: #cccccc;
  transition: color .1s ease; }

.swal2-modal .swal2-close:hover {
  color: #d55; }

.swal2-modal > .swal2-input,
.swal2-modal > .swal2-file,
.swal2-modal > .swal2-textarea,
.swal2-modal > .swal2-select,
.swal2-modal > .swal2-radio,
.swal2-modal > .swal2-checkbox {
  display: none; }

.swal2-modal .swal2-content {
  font-size: 18px;
  text-align: center;
  font-weight: 300;
  position: relative;
  float: none;
  margin: 0;
  padding: 0;
  line-height: normal;
  color: #545454;
  word-wrap: break-word; }

.swal2-modal .swal2-input,
.swal2-modal .swal2-file,
.swal2-modal .swal2-textarea,
.swal2-modal .swal2-select,
.swal2-modal .swal2-radio,
.swal2-modal .swal2-checkbox {
  margin: 20px auto; }

.swal2-modal .swal2-input,
.swal2-modal .swal2-file,
.swal2-modal .swal2-textarea {
  width: 100%;
  box-sizing: border-box;
  font-size: 18px;
  border-radius: 3px;
  border: 1px solid #d9d9d9;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.06);
  transition: border-color box-shadow .3s; }

.swal2-modal .swal2-input.swal2-inputerror,
.swal2-modal .swal2-file.swal2-inputerror,
.swal2-modal .swal2-textarea.swal2-inputerror {
  border-color: #f27474 !important;
  box-shadow: 0 0 2px #f27474 !important; }

.swal2-modal .swal2-input:focus,
.swal2-modal .swal2-file:focus,
.swal2-modal .swal2-textarea:focus {
  outline: none;
  border: 1px solid #b4dbed;
  box-shadow: 0 0 3px #c4e6f5; }

.swal2-modal .swal2-input:focus::-webkit-input-placeholder,
.swal2-modal .swal2-file:focus::-webkit-input-placeholder,
.swal2-modal .swal2-textarea:focus::-webkit-input-placeholder {
  transition: opacity .3s .03s ease;
  opacity: .8; }

.swal2-modal .swal2-input:focus:-ms-input-placeholder,
.swal2-modal .swal2-file:focus:-ms-input-placeholder,
.swal2-modal .swal2-textarea:focus:-ms-input-placeholder {
  transition: opacity .3s .03s ease;
  opacity: .8; }

.swal2-modal .swal2-input:focus::placeholder,
.swal2-modal .swal2-file:focus::placeholder,
.swal2-modal .swal2-textarea:focus::placeholder {
  transition: opacity .3s .03s ease;
  opacity: .8; }

.swal2-modal .swal2-input::-webkit-input-placeholder,
.swal2-modal .swal2-file::-webkit-input-placeholder,
.swal2-modal .swal2-textarea::-webkit-input-placeholder {
  color: #e6e6e6; }

.swal2-modal .swal2-input:-ms-input-placeholder,
.swal2-modal .swal2-file:-ms-input-placeholder,
.swal2-modal .swal2-textarea:-ms-input-placeholder {
  color: #e6e6e6; }

.swal2-modal .swal2-input::placeholder,
.swal2-modal .swal2-file::placeholder,
.swal2-modal .swal2-textarea::placeholder {
  color: #e6e6e6; }

.swal2-modal .swal2-range input {
  float: left;
  width: 80%; }

.swal2-modal .swal2-range output {
  float: right;
  width: 20%;
  font-size: 20px;
  font-weight: 600;
  text-align: center; }

.swal2-modal .swal2-range input,
.swal2-modal .swal2-range output {
  height: 43px;
  line-height: 43px;
  vertical-align: middle;
  margin: 20px auto;
  padding: 0; }

.swal2-modal .swal2-input {
  height: 43px;
  padding: 0 12px; }

.swal2-modal .swal2-input[type='number'] {
  max-width: 150px; }

.swal2-modal .swal2-file {
  font-size: 20px; }

.swal2-modal .swal2-textarea {
  height: 108px;
  padding: 12px; }

.swal2-modal .swal2-select {
  color: #545454;
  font-size: inherit;
  padding: 5px 10px;
  min-width: 40%;
  max-width: 100%; }

.swal2-modal .swal2-radio {
  border: 0; }

.swal2-modal .swal2-radio label:not(:first-child) {
  margin-left: 20px; }

.swal2-modal .swal2-radio input,
.swal2-modal .swal2-radio span {
  vertical-align: middle; }

.swal2-modal .swal2-radio input {
  margin: 0 3px 0 0; }

.swal2-modal .swal2-checkbox {
  color: #545454; }

.swal2-modal .swal2-checkbox input,
.swal2-modal .swal2-checkbox span {
  vertical-align: middle; }

.swal2-modal .swal2-validationerror {
  background-color: #f0f0f0;
  margin: 0 -20px;
  overflow: hidden;
  padding: 10px;
  color: gray;
  font-size: 16px;
  font-weight: 300;
  display: none; }

.swal2-modal .swal2-validationerror::before {
  content: '!';
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #ea7d7d;
  color: #fff;
  line-height: 24px;
  text-align: center;
  margin-right: 10px; }

@supports (-ms-accelerator: true) {
  .swal2-range input {
    width: 100% !important; }
  .swal2-range output {
    display: none; } }

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .swal2-range input {
    width: 100% !important; }
  .swal2-range output {
    display: none; } }

.swal2-icon {
  width: 80px;
  height: 80px;
  border: 4px solid transparent;
  border-radius: 50%;
  margin: 20px auto 30px;
  padding: 0;
  position: relative;
  box-sizing: content-box;
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.swal2-icon.swal2-error {
  border-color: #f27474; }

.swal2-icon.swal2-error .swal2-x-mark {
  position: relative;
  display: block; }

.swal2-icon.swal2-error [class^='swal2-x-mark-line'] {
  position: absolute;
  height: 5px;
  width: 47px;
  background-color: #f27474;
  display: block;
  top: 37px;
  border-radius: 2px; }

.swal2-icon.swal2-error [class^='swal2-x-mark-line'][class$='left'] {
  transform: rotate(45deg);
  left: 17px; }

.swal2-icon.swal2-error [class^='swal2-x-mark-line'][class$='right'] {
  transform: rotate(-45deg);
  right: 16px; }

.swal2-icon.swal2-warning {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  color: #f8bb86;
  border-color: #facea8;
  font-size: 60px;
  line-height: 80px;
  text-align: center; }

.swal2-icon.swal2-info {
  font-family: 'Open Sans', sans-serif;
  color: #3fc3ee;
  border-color: #9de0f6;
  font-size: 60px;
  line-height: 80px;
  text-align: center; }

.swal2-icon.swal2-question {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  color: #87adbd;
  border-color: #c9dae1;
  font-size: 60px;
  line-height: 80px;
  text-align: center; }

.swal2-icon.swal2-success {
  border-color: #a5dc86; }

.swal2-icon.swal2-success [class^='swal2-success-circular-line'] {
  border-radius: 50%;
  position: absolute;
  width: 60px;
  height: 120px;
  transform: rotate(45deg); }

.swal2-icon.swal2-success [class^='swal2-success-circular-line'][class$='left'] {
  border-radius: 120px 0 0 120px;
  top: -7px;
  left: -33px;
  transform: rotate(-45deg);
  transform-origin: 60px 60px; }

.swal2-icon.swal2-success [class^='swal2-success-circular-line'][class$='right'] {
  border-radius: 0 120px 120px 0;
  top: -11px;
  left: 30px;
  transform: rotate(-45deg);
  transform-origin: 0 60px; }

.swal2-icon.swal2-success .swal2-success-ring {
  width: 80px;
  height: 80px;
  border: 4px solid rgba(165, 220, 134, 0.2);
  border-radius: 50%;
  box-sizing: content-box;
  position: absolute;
  left: -4px;
  top: -4px;
  z-index: 2; }

.swal2-icon.swal2-success .swal2-success-fix {
  width: 7px;
  height: 90px;
  position: absolute;
  left: 28px;
  top: 8px;
  z-index: 1;
  transform: rotate(-45deg); }

.swal2-icon.swal2-success [class^='swal2-success-line'] {
  height: 5px;
  background-color: #a5dc86;
  display: block;
  border-radius: 2px;
  position: absolute;
  z-index: 2; }

.swal2-icon.swal2-success [class^='swal2-success-line'][class$='tip'] {
  width: 25px;
  left: 14px;
  top: 46px;
  transform: rotate(45deg); }

.swal2-icon.swal2-success [class^='swal2-success-line'][class$='long'] {
  width: 47px;
  right: 8px;
  top: 38px;
  transform: rotate(-45deg); }

.swal2-progresssteps {
  font-weight: 600;
  margin: 0 0 20px;
  padding: 0; }

.swal2-progresssteps li {
  display: inline-block;
  position: relative; }

.swal2-progresssteps .swal2-progresscircle {
  background: #3085d6;
  border-radius: 2em;
  color: #fff;
  height: 2em;
  line-height: 2em;
  text-align: center;
  width: 2em;
  z-index: 20; }

.swal2-progresssteps .swal2-progresscircle:first-child {
  margin-left: 0; }

.swal2-progresssteps .swal2-progresscircle:last-child {
  margin-right: 0; }

.swal2-progresssteps .swal2-progresscircle.swal2-activeprogressstep {
  background: #3085d6; }

.swal2-progresssteps .swal2-progresscircle.swal2-activeprogressstep ~ .swal2-progresscircle {
  background: #add8e6; }

.swal2-progresssteps .swal2-progresscircle.swal2-activeprogressstep ~ .swal2-progressline {
  background: #add8e6; }

.swal2-progresssteps .swal2-progressline {
  background: #3085d6;
  height: .4em;
  margin: 0 -1px;
  z-index: 10; }

[class^='swal2'] {
  -webkit-tap-highlight-color: transparent; }

@keyframes showSweetAlert {
  0% {
    transform: scale(0.7); }
  45% {
    transform: scale(1.05); }
  80% {
    transform: scale(0.95); }
  100% {
    transform: scale(1); } }

@keyframes hideSweetAlert {
  0% {
    transform: scale(1);
    opacity: 1; }
  100% {
    transform: scale(0.5);
    opacity: 0; } }

.swal2-show {
  animation: showSweetAlert 0.3s; }

.swal2-show.swal2-noanimation {
  animation: none; }

.swal2-hide {
  animation: hideSweetAlert 0.15s forwards; }

.swal2-hide.swal2-noanimation {
  animation: none; }

@keyframes animate-success-tip {
  0% {
    width: 0;
    left: 1px;
    top: 19px; }
  54% {
    width: 0;
    left: 1px;
    top: 19px; }
  70% {
    width: 50px;
    left: -8px;
    top: 37px; }
  84% {
    width: 17px;
    left: 21px;
    top: 48px; }
  100% {
    width: 25px;
    left: 14px;
    top: 45px; } }

@keyframes animate-success-long {
  0% {
    width: 0;
    right: 46px;
    top: 54px; }
  65% {
    width: 0;
    right: 46px;
    top: 54px; }
  84% {
    width: 55px;
    right: 0;
    top: 35px; }
  100% {
    width: 47px;
    right: 8px;
    top: 38px; } }

@keyframes rotatePlaceholder {
  0% {
    transform: rotate(-45deg); }
  5% {
    transform: rotate(-45deg); }
  12% {
    transform: rotate(-405deg); }
  100% {
    transform: rotate(-405deg); } }

.swal2-animate-success-line-tip {
  animation: animate-success-tip 0.75s; }

.swal2-animate-success-line-long {
  animation: animate-success-long 0.75s; }

.swal2-success.swal2-animate-success-icon .swal2-success-circular-line-right {
  animation: rotatePlaceholder 4.25s ease-in; }

@keyframes animate-error-icon {
  0% {
    transform: rotateX(100deg);
    opacity: 0; }
  100% {
    transform: rotateX(0deg);
    opacity: 1; } }

.swal2-animate-error-icon {
  animation: animate-error-icon 0.5s; }

@keyframes animate-x-mark {
  0% {
    transform: scale(0.4);
    margin-top: 26px;
    opacity: 0; }
  50% {
    transform: scale(0.4);
    margin-top: 26px;
    opacity: 0; }
  80% {
    transform: scale(1.15);
    margin-top: -6px; }
  100% {
    transform: scale(1);
    margin-top: 0;
    opacity: 1; } }

.swal2-animate-x-mark {
  animation: animate-x-mark 0.5s; }

@keyframes rotate-loading {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

html, body {
  padding-top: 38px; }

.sweet-alert {
  font-family: 'Ubuntu', sans-serif !important; }

/********************************************************************/
/* BLOCK MARGIN */
/********************************************************************/
.mb {
  margin-bottom: 1em; }

.mt {
  margin-top: 1em; }

.block-margin {
  margin: 20px 0 !important; }

.block-margin-bottom, .block-margin-btm {
  margin-bottom: 20px !important; }

.block-margin-top {
  margin-top: 20px !important; }

.row-block-margin {
  margin: 10px 0 !important; }

.no-top-margin, .no-top {
  margin-top: 0 !important; }

.no-bottom-margin, .no-btm, .no-bottom {
  margin-bottom: 0 !important; }

.text-large {
  font-size: 1.2em; }

.text-xlarge {
  font-size: 1.5em; }

/********************************************************************/
/* ITEM BLOCK STYLES */
/********************************************************************/
.item-block {
  display: inline-block;
  border: 1px solid #eee;
  border-radius: 2px;
  padding: 15px;
  text-align: center;
  margin: 15px 15px 0 0;
  height: 175px;
  width: 100%; }

.item-block:last-child {
  margin-right: 0; }

.item-block:hover {
  border: 1px solid #dd4814;
  cursor: pointer; }

/********************************************************************/
/* NAVBAR */
/********************************************************************/
.navbar-nav > li > a {
  padding-top: 20px !important;
  padding-bottom: 0 !important;
  height: 60px; }

.navbar-brand {
  height: 60px;
  padding-top: 0 !important; }

.navbar {
  min-height: 60px !important; }

/********************************************************************/
/* JUMBOTRON */
/********************************************************************/
.tdn-jumbotron {
  margin-top: -28px;
  color: #fff;
  background: #ffc9b6;
  background: linear-gradient(to left, #FFCCBC, #d84f1f); }

/********************************************************************/
/* WISHLISTS */
/********************************************************************/
.label-wishlist {
  margin-bottom: 0 !important; }

/********************************************************************/
/* FOOTER */
/********************************************************************/
#footer {
  margin: 40px 0; }

/********************************************************************/
/* Fancy loading */
/********************************************************************/
.sk-cube-grid {
  width: 40px;
  height: 40px;
  margin: 100px auto; }

.sk-cube-grid .sk-cube {
  width: 33%;
  height: 33%;
  background-color: #DD4814;
  float: left;
  animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out; }

.sk-cube-grid .sk-cube1 {
  animation-delay: 0.2s; }

.sk-cube-grid .sk-cube2 {
  animation-delay: 0.3s; }

.sk-cube-grid .sk-cube3 {
  animation-delay: 0.4s; }

.sk-cube-grid .sk-cube4 {
  animation-delay: 0.1s; }

.sk-cube-grid .sk-cube5 {
  animation-delay: 0.2s; }

.sk-cube-grid .sk-cube6 {
  animation-delay: 0.3s; }

.sk-cube-grid .sk-cube7 {
  animation-delay: 0s; }

.sk-cube-grid .sk-cube8 {
  animation-delay: 0.1s; }

.sk-cube-grid .sk-cube9 {
  animation-delay: 0.2s; }

@keyframes sk-cubeGridScaleDelay {
  0%, 70%, 100% {
    transform: scale3D(1, 1, 1); }
  35% {
    transform: scale3D(0, 0, 1); } }

/*# sourceMappingURL=app.css.map */
